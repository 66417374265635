import { mapActions, mapGetters } from 'vuex'
import { debounce, filter  } from 'lodash'

export default {
  name: 'user-admins-counties',
  data () {
    return {
      selectedCountries: []
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile/profile',
      isLoading: 'userAdmins/isLoading',
      currentStudentLoading: 'userAdmins/currentStudentLoading',
      currentUser: 'userAdmins/currentStudent',
      accessCountries: 'profile/accessCountries',
      basicCountries: 'setting/basicCountries'
    })
  },
  created () {
    if (!this.currentUser) {
      this.fetchCurrentUser({ id: this.$route.params.id }).then(() => {
        this.findSelectedCountryInList()
      })
    } else {
      this.findSelectedCountryInList()
    }
  },
  methods: {
    ...mapActions({
      fetchCurrentUser: 'userAdmins/GET_LIST_ITEM',
      updateCountries: 'userAdmins/UPDATE_COUNTRIES'
    }),
    findSelectedCountryInList () {
      let accessUserCountries = filter(this.currentUser.countries.data, el => {
        return el.type === 'access';
      });

      this.basicCountries.forEach(e => {
        const countryData = accessUserCountries.find(t => t.country_id === e.id)
        if (countryData) {
          this.selectedCountries.push(e.id)
        }
      })
    },
    updateCountriesLocal () {
      this.updateCountries({
        countries: this.selectedCountries,
        userId: this.$route.params.id,
        _method: 'patch'
      }).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    changeInput: debounce(function () {
      this.updateCountriesLocal()
    }, 500)
  }
}
